@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

* {
    box-sizing: border-box;
    user-select: text;
}


body {
     font-family: 'Dice', sans-serif;
    padding: 0;
    margin: 0;
    background-color: var(--primary-color);
    box-sizing: border-box;

}

.light {
     --background:#f9fafa;
    --primary-color: #F6F7FC;
    --text-color: #0b0d33;
    --button-color: #6c5dd3;
    --sidebar-active-color: #353535;
    --sidebar-text-color: #f5f6fa;
    --right-backgroundColor: #fff;
    --inactive-color: #5e6b6e;
    --info-background: #7987c9; /* previous color #9da1e2 */
    --info-color: #fff;
    --active-border-color: #6c5dd3;
    --title-desc-color: #5e6b6e; /* TitleBar Component desc color */
    --border-color: #ddd;
    --card-color: #6c5dd3;
    --active-background: #6c5dd3;
    --toggle-background: #eceef9;
    --input-backgroundColor: #F6F7FC;
    --input-color: #33363b;
    --red-color: #dc3545;
    --animate-1: #eceef9;
    --animate-2: #d9ddf2;
    --landing-box:#212121;
    --sidebar-color:#fff;
    --tile-color: #fff;
    --tile-border-color:#eee;
    --tile-light-color: #f2f2f2;
    --off-white:#F9F9FA;
    --sidebar-background-active: rgba(41, 94, 255, 0.1);
    --sidebar-background-hover: rgba(41, 98, 255, 0.1);
    --sidebar-item-active: #5f3df5;
    --sidebar-item-inactive: #2e3235;
    --white-color:#fff;

}


input:focus {
    outline: none;
}


h1, h2, h3, h4, h5, h6, p {
    padding: 0px;
    margin: 0px;
}

.rc-slider-track {
    background-color: #777 !important;
}

.dot-parent .dots {
    display: none;
}

.dot-parent .dots:hover {
    display: block;
}
